/*****************************************************************
 * This file contains every pattern related classes and functions
 *****************************************************************/


import { Shar3dUtils } from '@shar3d/shar3d-utils';

import { BKOrderSourceEnum, BKOrderSourceMask } from './BKOrderSourceEnum';
import { ICsiExport_elocalisation } from './law';
import { IBKExchangedOrderDiscount } from './myBKCommonAPI';
import {
	IBKDiscountInOrderDataCassandra,
	IBKOrderEventDataCassandra,
	IBKPublishedOrderDataCassandra
} from './myBKCommonCassandra.generated';
import { BKCampaignDataImpl } from './myBKCommonClasses.generated';
import {
	IBKAllergenData,
	IBKAnnotationData,
	IBKBigData,
	IBKCampaignData,
	IBKCampaignLocalPricesData,
	IBKCouponServiceData,
	IBKDiscountData,
	IBKGameData,
	IBKIngredientData,
	IBKItemBase,
	IBKMenuBase,
	IBKNavigationScreenData,
	IBKOrderEventData,
	IBKPickUpTypeParkingDetails,
	IBKProductBase,
	IBKProductFamilyData,
	IBKPublishedOrderData,
	IBKSelectionPattern,
	IBKTimePlanification
} from './myBKCommonDatas';
import {
	BKBurgerMystereInOrderEventKey,
	BKCampaignFidelityFilterTypeEnum,
	BKDeliveryModeEnum,
	BKInfoOrderTabletEventKey,
	BKItemSelectionPatternSourceEnum,
	BKKingdomInOrderEventKey,
	BKOrderEventType,
	BKPickUpTypeEnum,
	BKTableAllocationLocationSpace,
	BKTableAllocationType
} from './myBKCommonEnums';

export interface IBKBigDataProcessed<P extends IBKProductBase,
	M extends IBKMenuBase,
	D extends IBKDiscountData,
	A extends IBKAnnotationData,
	G extends IBKGameData,
	F extends IBKProductFamilyDataWithContent<P, F>,
	C extends IBKCouponServiceData,
	T extends IBKIngredientData> {
	$products: P[];
	$productsById: { [id: number]: P };

	//                            locThis.$allFamilies.allFamilies,
	//$allFamilies: F,
	$familiesById: { [id: number]: F };

	$menus: M[];
	$menusById: { [id: number]: M };

	$ingredients: T[];
	$ingredientsById: { [id: string]: T };
}

export class BKPatternUtilities {
	/**
	 * List the products from the ancestors
	 */
	public static listProductFromFamilyAndAncestors<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(family: F, familiesById: { [id: number]: F }): T[] {
		//  Check for a parent
		if (family.parentFamilyId <= 0) return family.$products;
		//  Check for the parent to exist
		if (_BK.isUndefined(familiesById[family.parentFamilyId])) return family.$products;
		//  Call the parent and merge
		const ap: T[] = BKPatternUtilities.listProductFromFamilyAndAncestors<T, F>(familiesById[family.parentFamilyId], familiesById);
		return _BK.union<T>(family.$products, ap);
	}

	/**
	 * List the product for the family and its childs
	 */
	public static listProductFromSelfAndChilds<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(familyId: number, familiesById: { [id: number]: F }): T[] {
		/** Return nothing if the family with ID doesn't exist */
		if (!familiesById[familyId]) return [];
		/** Return products from this family, if the family has no child families */
		if (!familiesById[familyId].$childFamilies || familiesById[familyId].$childFamilies.length <= 0) return familiesById[familyId].$products;
		/** Otherwise return products from child family and union them with products from this parent family */
		return _BK.union(familiesById[familyId].$products || [], BKPatternUtilities.listProductFromChilds<T, F>(familiesById[familyId].$childFamilyIds, familiesById) || []);
	}

	/**
	 * Recursive list of products from child families
	 */
	public static listProductFromChilds<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(
		childFamilyIds: number[],
		familiesById: { [id: number]: F }
	): T[] {
		/** Return nothing if there are no child families set */
		if (!childFamilyIds || childFamilyIds.length <= 0) return [];

		let a: T[] = BKPatternUtilities.listProductFromSelfAndChilds<T, F>(childFamilyIds[0], familiesById);
		for (let i = 1; i < childFamilyIds.length; i++) {
			a = _BK.union(a, BKPatternUtilities.listProductFromSelfAndChilds<T, F>(childFamilyIds[i], familiesById));
		}
		return a;
	}

	/**
	 * 0 : Specified familly only
	 * 1 : Familly and its childs
	 * 2 : Childs of the selected familly ( not the familly itself )
	 * -1 : Familly and its ancestors
	 * -2 : Ancestors only ( not the familly itself )
	 */
	public static listProductInFamilies<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(
		familiesById: { [id: number]: F }, //datas: BigDatas,
		familyId: number,
		propagation: number
	): T[] {
		if (familyId <= 0) return [];
		if (_BK.isUndefined(familiesById[familyId])) return [];
		//  Load
		const family: F = familiesById[familyId];
		//  Check for the propagation
		switch (propagation) {
			case 0: //  Self only
				return family.$products;
			case 1:
				return BKPatternUtilities.listProductFromSelfAndChilds<T, F>(familyId, familiesById);
			case 2:
				return BKPatternUtilities.listProductFromChilds<T, F>(family.$childFamilyIds, familiesById);
			case -1:
				return BKPatternUtilities.listProductFromFamilyAndAncestors<T, F>(family, familiesById);
			case -2:
				// eslint-disable-next-line no-case-declarations
				const parentId: number = family.parentFamilyId;
				if (parentId <= 0) return [];
				if (_BK.isUndefined(familiesById[parentId])) return [];
				return BKPatternUtilities.listProductFromFamilyAndAncestors<T, F>(familiesById[parentId], familiesById);
			default:
				//  Should not happen
				return [];
		}
	}

	/**
	 * List the ingredients by family
	 */
	public static listIngredientsByFamily<T extends IBKIngredientData>(ingredients: T[], familyId: number): T[] {
		const a: T[] = [];
		//  Lookup
		for (const i of ingredients) {
			if (i._familyId === familyId) a.push(i);
		}
		return a;
	}

	/**
	 * Check for at least one tag to be present
	 */
	public static hasOneTag(itemTags: number[], lookupTags: number[]) {
		if (itemTags.length <= 0) return false;
		return _BK.intersection(itemTags, lookupTags).length > 0;
	}

	/**
	 * Check for at least one tag to be present
	 */
	public static hasAllTags(itemTags: number[], lookupTags: number[]) {
		if (itemTags.length <= 0) return false;
		return _BK.intersection(itemTags, lookupTags).length === lookupTags.length;
	}

	/**
	 * Convert indexes to an array of products
	 */
	public static indexesToProducts<T extends IBKProductBase>(productById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (let i = 0; i < indexes.length; i++) {
			if (_BK.isDefined(productById[indexes[i]])) a.push(productById[indexes[i]]);
		}
		return a;
	}

	/**
	 * Convert indexes to an array of menus
	 */
	public static indexesToMenus<T extends IBKMenuBase>(menusById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (let i = 0; i < indexes.length; i++) {
			if (_BK.isDefined(menusById[indexes[i]])) a.push(menusById[indexes[i]]);
		}
		return a;
	}

	/**
	 * Convert a list of items to their respective indexes
	 */
	public static itemsToIndexes<T extends IBKItemBase>(items: T[]): number[] {
		const a: number[] = [];
		for (let i = 0; i < items.length; i++) a.push(items[i].id);
		return a;
	}

	/**
	 * Convert a list of ingredients to a list of indexes
	 */
	public static ingredientsToIndexes(items: IBKIngredientData[]): number[] {
		const a: number[] = [];
		for (let i = 0; i < items.length; i++) a.push(items[i].id);
		return a;
	}

	/**
	 * Convert a list of indexes to a list of ingredients
	 */
	public static indexesToIngredients<T extends IBKIngredientData>(ingredientsById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(ingredientsById[idx])) a.push(ingredientsById[idx]);
		}
		return a;
	}

	/**
	 * Convert a list of indexes to a list of ingredients
	 */
	public static indexesToAllergens<T extends IBKAllergenData>(allergensById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(allergensById[idx])) a.push(allergensById[idx]);
		}
		return a;
	}

	/**
	 * Convert a list of indexes to a list of discounts
	 */
	public static indexesToDiscounts<T extends IBKDiscountData>(discountsById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(discountsById[idx])) a.push(discountsById[idx]);
		}
		return a;
	}

	/**
	 * Convert a list of indexes to a list of annotations
	 */
	public static indexesToAnnotations<T extends IBKAnnotationData>(annotationsById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(annotationsById[idx])) a.push(annotationsById[idx]);
		}
		return a;
	}

	/**
	 * Convert a list of indexes to a list of games
	 */
	public static indexesToGames<T extends IBKGameData>(gamesById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(gamesById[idx])) a.push(gamesById[idx]);
		}
		return a;
	}

	/**
	 * Convert a list of indexes to a list of coupons
	 */
	public static indexesToCoupons<T extends IBKCouponServiceData>(couponsById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(couponsById[idx])) a.push(couponsById[idx]);
		}
		return a;
	}

	/**
	 * Convert indexes to navigation screens
	 */
	public static indexesToNavigationScreen<T extends IBKNavigationScreenData>(screensById: { [id: number]: T }, indexes: number[]): T[] {
		const a: T[] = [];
		for (const idx of indexes) {
			if (_BK.isDefined(screensById[idx])) a.push(screensById[idx]);
		}
		return a;
	}

	/**
	 * Filter the products regarding a pattern
	 */
	public static filterProducts<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(
		pattern: IBKSelectionPattern,
		products: T[],
		productById: { [id: number]: T },
		familiesById: { [id: number]: F }
	): T[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.PRODUCT) return [];
		//  Get the global source
		let a: T[] = pattern.family > 0 ? BKPatternUtilities.listProductInFamilies<T, F>(familiesById, pattern.family, pattern.familyPropagation) : products;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<T>(a, function (p: T): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<T>(a, function (p: T): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToProducts(productById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToProducts(productById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	public static filterProductsMulti<T extends IBKProductBase,
		F extends IBKProductFamilyDataWithContent<T, F>>(
			patterns: IBKSelectionPattern[],
			products: T[],
			productById: { [id: number]: T },
			familiesById: { [id: number]: F }
		): T[] {
		//  Resulting array
		let a: T[] = [];
		//  Start with the inclusive
		for (const p of patterns) {
			if (!p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterProducts(p, products, productById, familiesById);
				if (b.length > 0) a = _BK.union(a, b);
			}
		}
		//  Continue with the exclusive
		for (const p of patterns) {
			if (p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterProducts(p, products, productById, familiesById);
				if (b.length > 0) a = _BK.difference(a, b);
			}
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the menus according to a pattern
	 */
	public static filterMenus<M extends IBKMenuBase>(pattern: IBKSelectionPattern, menus: M[], menusById: { [id: number]: M }): M[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.MENU) return [];
		//  Initial all
		let a: M[] = menus;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<M>(a, function (p: M): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<M>(a, function (p: M): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToMenus(menusById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToMenus(menusById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	public static filterMenusMulti<M extends IBKMenuBase>(patterns: IBKSelectionPattern[], menus: M[], menusById: { [id: number]: M }): M[] {
		let a: M[] = [];
		//  Start with the inclusive
		for (const p of patterns) {
			if (!p.excludeFilter) {
				const b: M[] = BKPatternUtilities.filterMenus(p, menus, menusById);
				if (b.length > 0) a = _BK.union(a, b);
			}
		}
		//  Continue with the exclusive
		for (const p of patterns) {
			if (p.excludeFilter) {
				const b: M[] = BKPatternUtilities.filterMenus(p, menus, menusById);
				if (b.length > 0) a = _BK.difference(a, b);
			}
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the ingredients according to a pattern
	 */
	public static filterIngredients<T extends IBKIngredientData>(pattern: IBKSelectionPattern, ingredients: T[], ingredientsById: { [id: number]: T }): T[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.INGREDIENT) return [];
		//  Initial all
		let a: T[] = pattern.family <= 0 ? ingredients : BKPatternUtilities.listIngredientsByFamily(ingredients, pattern.family);
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<T>(a, function (p: T): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<T>(a, function (p: T): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToIngredients(ingredientsById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToIngredients(ingredientsById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	public static filterIngredientsMulti<T extends IBKIngredientData>(patterns: IBKSelectionPattern[], ingredients: T[], ingredientsById: { [id: number]: T }): T[] {
		let a: T[] = [];
		//  Start with the inclusive
		for (const p of patterns) {
			if (!p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterIngredients(p, ingredients, ingredientsById);
				if (b.length > 0) a = _BK.union(a, b);
			}
		}
		//  Continue with the exclusive
		for (const p of patterns) {
			if (p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterIngredients(p, ingredients, ingredientsById);
				if (b.length > 0) a = _BK.difference(a, b);
			}
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the discounts (multi)
	 *
	 * @param {type} patterns
	 * @param {type} discounts
	 * @param {type} discountsById
	 * @returns {IBKDiscountData[]}
	 */
	public static filterDiscountsMulti<T extends IBKDiscountData>(patterns: IBKSelectionPattern[], discounts: T[], discountsById: { [id: number]: T }): T[] {
		let a: T[] = [];
		//  Start with the inclusive
		for (const p of patterns) {
			if (!p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterDiscounts(p, discounts, discountsById);
				if (b.length > 0) a = _BK.union(a, b);
			}
		}
		//  Continue with the exclusive
		for (const p of patterns) {
			if (p.excludeFilter) {
				const b: T[] = BKPatternUtilities.filterDiscounts(p, discounts, discountsById);
				if (b.length > 0) a = _BK.difference(a, b);
			}
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the discounts according to a pattern
	 */
	public static filterDiscounts<D extends IBKDiscountData>(pattern: IBKSelectionPattern, discounts: D[], discountsById: { [id: number]: D }): D[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.DISCOUNT) return [];
		//  Initial all
		let a: D[] = discounts;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<D>(a, function (p: D): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<D>(a, function (p: D): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToDiscounts(discountsById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToDiscounts(discountsById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the annotations according to a filter
	 */
	public static filterAnnotations<A extends IBKAnnotationData>(pattern: IBKSelectionPattern, annotations: A[], annotationsById: { [id: number]: A }): A[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.ANNOTATION) return [];
		//  Initial all
		let a: A[] = annotations;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<A>(a, function (p: A): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<A>(a, function (p: A): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToAnnotations(annotationsById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToAnnotations(annotationsById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the games according to a filter
	 */
	public static filterGames<G extends IBKGameData>(pattern: IBKSelectionPattern, games: G[], gamesById: { [id: number]: G }): G[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.GAME) return [];
		//  Initial all
		let a: G[] = games;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<G>(a, function (p: G): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<G>(a, function (p: G): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToGames(gamesById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToGames(gamesById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the coupons according to a filter
	 */
	public static filterCoupons<C extends IBKCouponServiceData>(pattern: IBKSelectionPattern, coupons: C[], couponsById: { [id: number]: C }): C[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.COUPON) return [];
		//  Initial all
		let a: C[] = coupons;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<C>(a, function (p: C): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<C>(a, function (p: C): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p._xtag || [], pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p._xtag || [], pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToCoupons(couponsById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToCoupons(couponsById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the kiosk navigation screens
	 */
	public static filterKioskNavigations<N extends IBKNavigationScreenData>(pattern: IBKSelectionPattern, screens: N[], screensById: { [id: number]: N }): N[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.KIOSK_NAVIGATION_MENU) return [];
		//  Initial all
		let a: N[] = screens;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<N>(a, function (p: N): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<N>(a, function (p: N): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToNavigationScreen(screensById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToNavigationScreen(screensById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the POS navigation screens
	 */
	public static filterPOSNavigations<N extends IBKNavigationScreenData>(pattern: IBKSelectionPattern, screens: N[], screensById: { [id: number]: N }): N[] {
		//  Check for the target
		if (pattern.target !== BKItemSelectionPatternSourceEnum.POS_NAVIGATION_MENU) return [];
		//  Initial all
		let a: N[] = screens;
		//  Check for the tags to include
		if (pattern.withTags.length > 0) {
			a = _BK.filter<N>(a, function (p: N): boolean {
				if (pattern.withAllTags) return BKPatternUtilities.hasAllTags(p.xtag, pattern.withTags);
				else return BKPatternUtilities.hasOneTag(p.xtag, pattern.withTags);
			});
		}
		//  Check for the tags to exclude
		if (pattern.withoutTags.length > 0) {
			a = _BK.filter<N>(a, function (p: N): boolean {
				if (pattern.withoutAllTags) return !BKPatternUtilities.hasAllTags(p.xtag, pattern.withoutTags);
				else return !BKPatternUtilities.hasOneTag(p.xtag, pattern.withoutTags);
			});
		}
		//  Check for selected
		if (pattern.selected.length > 0) {
			a = _BK.intersection(a, BKPatternUtilities.indexesToNavigationScreen(screensById, pattern.selected));
		}
		//  Check for excluded
		if (pattern.excluded.length > 0) {
			a = _BK.difference(a, BKPatternUtilities.indexesToNavigationScreen(screensById, pattern.excluded));
		}
		//  Finished
		return a;
	}

	/**
	 * Filter the kiosk navigation screens
	 */
	public static filterKioskNavigationsMulti<N extends IBKNavigationScreenData>(patterns: IBKSelectionPattern[], screens: N[], screensById: { [id: number]: N }): N[] {
		//BKKioskNavigationMenu[]{
		let a: N[] = [];
		//  Start with the inclusive
		for (const p of patterns) {
			if (!p.excludeFilter) {
				const b: N[] = BKPatternUtilities.filterKioskNavigations(p, screens, screensById);
				if (b.length > 0) a = _BK.union(a, b);
			}
		}
		//  Continue with the exclusive
		for (const p of patterns) {
			if (p.excludeFilter) {
				const b: N[] = BKPatternUtilities.filterKioskNavigations(p, screens, screensById);
				if (b.length > 0) a = _BK.difference(a, b);
			}
		}
		//  Finished
		return a;
	}

	//!!! This is write-only code... I dont really know what it does
	/**
	 * Filter mixed values
	 */
	public static filterMixedForKiosk<P extends IBKProductBase,
		M extends IBKMenuBase,
		N extends IBKNavigationScreenData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>>(
			patterns: IBKSelectionPattern[],
			products: P[],
			productById: { [id: number]: P },
			familiesById: { [id: number]: F },
			menus: M[],
			menusById: { [id: number]: M },
			screens: N[],
			screensById: { [id: number]: N },
			games: G[],
			gamesById: { [id: number]: G }
		): Array<P | M | N | G> {
		let a: Array<P | M | N | G> = [];
		//  Process each filter
		for (const pattern of patterns) {
			switch (pattern.target) {
				//  Product
				case BKItemSelectionPatternSourceEnum.PRODUCT:
					// eslint-disable-next-line no-case-declarations
					const aP: P[] = BKPatternUtilities.filterProducts(pattern, products, productById, familiesById)
						.filter(function (p: P) {
							return !p.noKiosk;
						})
						.sort(function (a: P, b: P) {
							const aI: number = _BK.isDefined(a._indexKiosk) ? a._indexKiosk : 0;
							const bI: number = _BK.isDefined(b._indexKiosk) ? b._indexKiosk : 0;
							return aI - bI;
						});
					if (pattern.excludeFilter) {
						a = _BK.without(a, aP);
					} else {
						a = _BK.union(a, aP);
					}
					break;
				//  Menu
				case BKItemSelectionPatternSourceEnum.MENU:
					// eslint-disable-next-line no-case-declarations
					const aM: M[] = BKPatternUtilities.filterMenus(pattern, menus, menusById)
						.filter(function (m: M) {
							return !m.noKiosk;
						})
						.sort(function (a: M, b: M) {
							const aI: number = _BK.isDefined(a._indexKiosk) ? a._indexKiosk : 0;
							const bI: number = _BK.isDefined(b._indexKiosk) ? b._indexKiosk : 0;
							return aI - bI;
						});
					if (pattern.excludeFilter) {
						a = _BK.without(a, aM);
					} else {
						a = _BK.union(a, aM);
					}
					break;
				//  Navigation screen
				case BKItemSelectionPatternSourceEnum.KIOSK_NAVIGATION_MENU:
					// eslint-disable-next-line no-case-declarations
					const aN: N[] = BKPatternUtilities.filterKioskNavigations(pattern, screens, screensById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aN);
					} else {
						a = _BK.union(a, aN);
					}
					break;
				//  Games
				case BKItemSelectionPatternSourceEnum.GAME:
					// eslint-disable-next-line no-case-declarations
					const aG: G[] = BKPatternUtilities.filterGames(pattern, games, gamesById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aG);
					} else {
						a = _BK.union(a, aG);
					}
					break;
			}
		}
		return a;
	}

	/**
	 * Filter mixed values
	 */
	public static filterMixedForCash<P extends IBKProductBase,
		M extends IBKMenuBase,
		D extends IBKDiscountData,
		A extends IBKAnnotationData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>,
		C extends IBKCouponServiceData>(
			patterns: IBKSelectionPattern[],
			products: P[],
			productById: { [id: number]: P },
			familiesById: { [id: number]: F },
			menus: M[],
			menusById: { [id: number]: M },
			discounts: D[],
			discountsById: { [id: number]: D },
			annotations: A[],
			annotationsById: { [id: number]: A },
			games: G[],
			gamesById: { [id: number]: G },
			coupons: C[],
			couponsById: { [id: number]: C }
		): Array<P | M | D | A | G | C> {
		//  Resulting array
		let a: Array<P | M | D | A | G | C> = [];
		for (const pattern of patterns) {
			switch (pattern.target) {
				//  Product
				case BKItemSelectionPatternSourceEnum.PRODUCT:
					// eslint-disable-next-line no-case-declarations
					const aP: P[] = BKPatternUtilities.filterProducts(pattern, products, productById, familiesById).sort(function (a: P, b: P) {
						const aI: number = _BK.isDefined(a._indexPOS) ? a._indexPOS : 0;
						const bI: number = _BK.isDefined(b._indexPOS) ? b._indexPOS : 0;
						return aI - bI;
					});
					if (pattern.excludeFilter) {
						a = _BK.without(a, aP);
					} else {
						a = _BK.union(a, aP);
					}
					break;
				//  Menu
				case BKItemSelectionPatternSourceEnum.MENU:
					// eslint-disable-next-line no-case-declarations
					const aM: M[] = BKPatternUtilities.filterMenus(pattern, menus, menusById).sort(function (a: M, b: M) {
						const aI: number = _BK.isDefined(a._indexPOS) ? a._indexPOS : 0;
						const bI: number = _BK.isDefined(b._indexPOS) ? b._indexPOS : 0;
						return aI - bI;
					});
					if (pattern.excludeFilter) {
						a = _BK.without(a, aM);
					} else {
						a = _BK.union(a, aM);
					}
					break;
				//  Discount
				case BKItemSelectionPatternSourceEnum.DISCOUNT:
					// eslint-disable-next-line no-case-declarations
					const aD: D[] = BKPatternUtilities.filterDiscounts(pattern, discounts, discountsById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aD);
					} else {
						a = _BK.union(a, aD);
					}
					break;
				//  Annotation
				case BKItemSelectionPatternSourceEnum.ANNOTATION:
					// eslint-disable-next-line no-case-declarations
					const aA: A[] = BKPatternUtilities.filterAnnotations(pattern, annotations, annotationsById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aA);
					} else {
						a = _BK.union(a, aA);
					}
					break;
				//  Games
				case BKItemSelectionPatternSourceEnum.GAME:
					// eslint-disable-next-line no-case-declarations
					const aG: G[] = BKPatternUtilities.filterGames(pattern, games, gamesById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aG);
					} else {
						a = _BK.union(a, aG);
					}
					break;
				//  Coupons
				case BKItemSelectionPatternSourceEnum.COUPON:
					// eslint-disable-next-line no-case-declarations
					const aC: C[] = BKPatternUtilities.filterCoupons(pattern, coupons, couponsById);
					if (pattern.excludeFilter) {
						a = _BK.without(a, aC);
					} else {
						a = _BK.union(a, aC);
					}
					break;
			}
		}
		return a;
	}

	/**
	 * Filter mixed values
	 */
	public static filterMixedForCampaign<P extends IBKProductBase, M extends IBKMenuBase, I extends IBKIngredientData, F extends IBKProductFamilyDataWithContent<P, F>>(
		patterns: IBKSelectionPattern[],
		products: P[],
		productById: { [id: number]: P },
		familiesById: { [id: number]: F },
		menus: M[],
		menusById: { [id: number]: M },
		ingredients: I[],
		ingredientsById: { [id: number]: I }
	): { products: P[]; menus: M[]; ingredients: I[] } {
		//  Resulting array
		const a: { products: P[]; menus: M[]; ingredients: I[] } = { products: [], menus: [], ingredients: [] };
		for (const pattern of patterns) {
			switch (pattern.target) {
				//  Product
				case BKItemSelectionPatternSourceEnum.PRODUCT:
					// eslint-disable-next-line no-case-declarations
					const aP: P[] = BKPatternUtilities.filterProducts(pattern, products, productById, familiesById);
					if (pattern.excludeFilter) {
						a.products = _BK.without(a.products, aP);
					} else {
						a.products = _BK.union(a.products, aP);
					}
					break;
				//  Menu
				case BKItemSelectionPatternSourceEnum.MENU:
					// eslint-disable-next-line no-case-declarations
					const aM: M[] = BKPatternUtilities.filterMenus(pattern, menus, menusById);
					if (pattern.excludeFilter) {
						a.menus = _BK.without(a.menus, aM);
					} else {
						a.menus = _BK.union(a.menus, aM);
					}
					break;
				case BKItemSelectionPatternSourceEnum.INGREDIENT:
					// eslint-disable-next-line no-case-declarations
					const aI: I[] = BKPatternUtilities.filterIngredients(pattern, ingredients, ingredientsById);
					if (pattern.excludeFilter) {
						a.ingredients = _BK.without(a.ingredients, aI);
					} else {
						a.ingredients = _BK.union(a.ingredients, aI);
					}
					break;
			}
		}
		return a;
	}

	/**
	 * Generate the list of product families data with content
	 */
	public static generateProductFamiliesWithContent<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>>(
		families: IBKProductFamilyData[],
		products: IBKProductBase[]
	): F[] {
		// Init init list by id
		const list: F[] = [];
		// Loop on families
		let i = 0;
		const j = 0;
		for (i = 0; i < families.length; i++) {
			const productsForThisFamily: IBKProductBase[] = products.filter((p) => p._familyId === families[i].id);
			const childFamilyForThisFamily: IBKProductFamilyData[] = families.filter((f) => f.parentFamilyId === families[i].id);
			// Add family with content
			list.push(<F>{
				id: families[i].id,
				_name: families[i]._name,
				_description: families[i]._description,
				parentFamilyId: families[i].parentFamilyId,
				_picto: families[i]._picto || 0,
				$products: productsForThisFamily || [],
				$productIds: productsForThisFamily.map((f) => f.id) || [],
				$childFamilies: childFamilyForThisFamily || [],
				$childFamilyIds: childFamilyForThisFamily.map((f) => f.id) || []
			});
		}
		// Return list by id
		return list;
	}
}

export interface IBKProductFamilyDataWithContent<T extends IBKProductBase, F extends IBKProductFamilyDataWithContent<T, F>> extends IBKProductFamilyData {
	$products: T[];
	$productIds: number[];
	$childFamilies: F[];
	$childFamilyIds: number[];
}

/**
 * Utility class
 */
export class _BK {
	/**
	 * Convert a flag to a mask
	 */
	public static flagToMask(flag: number): number {
		return 2 ** flag;
	}

	/**
	 * Test if a flag is set
	 */
	public static flagsTest(val: number, flagVal: number): boolean {
		return (val & flagVal) > 0;
	}

	/**
	 * Convert a value to a mask value and test it
	 */
	public static valueInMaskTest(value: number, mask: number): boolean {
		const v = _BK.flagToMask(value);
		return _BK.flagsTest(v, mask);
	}

	/**
	 * Decode a mask value to get a list of mask
	 */
	public static decodeMaskIntoList<T extends number>(mask: number, availableMasks: T[]): T[] {
		return (availableMasks || []).filter((availableMask) => _BK.flagsTest(mask, availableMask));
	}

	public static isSet<T>(value: T | null | undefined): value is T {
		return _BK.isDefined(value) && value !== null;
	}

	public static isNumber(value: any): value is number {
		return typeof value === 'number';
	}

	public static isFiniteNumber(value: any): value is number {
		return _BK.isNumber(value) && isFinite(value);
	}

	public static isIntegerNumber(value: any): value is number {
		return _BK.isNumber(value) && isFinite(value) && Math.floor(value) === value;
	}

	public static isUndefined(value: any): value is undefined {
		return typeof value === 'undefined';
	}

	/**
	 * Same implementation as in AngularJS.
	 * See https://github.com/angular/angular.js/blob/master/src/Angular.js#L643
	 */
	public static isFunction(value) {
		return typeof value === 'function';
	}

	/**
	 * @ngdoc function
	 * @name angular.isDefined
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is defined.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is defined.
	 */
	public static isDefined<T>(value: T | null | undefined): value is T {
		return typeof value !== 'undefined';
	}

	/**
	 * @ngdoc function
	 * @name angular.isObject
	 * @module ng
	 * @kind function
	 *
	 * @description
	 * Determines if a reference is an `Object`. Unlike `typeof` in JavaScript, `null`s are not
	 * considered to be objects. Note that JavaScript arrays are objects.
	 *
	 * @param {*} value Reference to check.
	 * @returns {boolean} True if `value` is an `Object` but not `null`.
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public static isObject<T extends object>(value: any): value is T {
		return value !== null && typeof value === 'object';
	}

	/**
	 * Filter some elements in the array
	 */
	public static filter<T>(source: T[], predicate: (t: T) => boolean): T[] {
		const result: T[] = [];
		for (const t of source) {
			if (predicate(t)) {
				result.push(t);
			}
		}
		return result;
	}

	/**
	 * Return the union of several arrays ( exclude the duplicated )
	 */
	public static union<T>(a: T[], b: T[]): T[] {
		const result: T[] = a.slice();
		for (const t of b) {
			if (a.indexOf(t) < 0) {
				result.push(t);
			}
		}
		return result;
	}

	/**
	 * Compute the intersection of two arrays
	 */
	public static intersection<T>(a: T[], b: T[]): T[] {
		const result: T[] = [];
		for (const t of b) {
			if (a.indexOf(t) >= 0) {
				result.push(t);
			}
		}
		return result;
	}

	/**
	 * Compute the difference between two arrays
	 */
	public static difference<T>(a: T[], b: T[]): T[] {
		const result: T[] = [];
		for (const t of a) {
			if (b.indexOf(t) < 0) {
				result.push(t);
			}
		}
		return result;
	}

	/**
	 * Return the a array without elements in the b array
	 */
	public static without<T>(a: T[], b: T[]): T[] {
		const result: T[] = [];
		for (const t of a) {
			if (b.indexOf(t) < 0) {
				result.push(t);
			}
		}
		return result;
	}

	/**
	 * Own version of ES6 method's 'Object.values'
	 */
	public static values<T>(obj: Record<string | number, T>): T[] {
		return Object.keys(obj || []).map((e) => (<any>obj)[e]);
	}

	/**
	 * Convert a simple list to an list by id (Object)
	 */
	public static getItemListByIds<T extends { id: number }>(list: T[]): { [id: number]: T } {
		// Check list
		if (!list) return {};
		// Init list by id
		const listById: { [id: number]: T } = {};
		// Loop on list
		for (const item of list) {
			if (_BK.isDefined(item.id)) listById[item.id] = item;
		}
		// Return list by id
		return listById;
	}

	/**
	 * Get the query variables
	 */
	public static getQueryVariables(query: string): { [key: string]: string } {
		const args: { [key: string]: string } = {};
		const vars = (query || '').split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			if (pair.length !== 2) continue;
			args[pair[0]] = decodeURIComponent(pair[1]);
		}
		return args;
	}

	/**
	 * Returns a IBKBigDataProcessed object
	 */
	public static computeBigDataProcessedObject<P extends IBKProductBase,
		M extends IBKMenuBase,
		D extends IBKDiscountData,
		A extends IBKAnnotationData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>,
		C extends IBKCouponServiceData,
		T extends IBKIngredientData>(bigdata: IBKBigData): IBKBigDataProcessed<P, M, D, A, G, F, C, T> | null {
		if (!bigdata) {
			return null;
		}
		// Function to extract values from object
		const values = <T, M>(obj: { [key: string]: T }, convertFun: (t: T) => M): M[] => {
			if (!obj) return [];
			return Object.keys(obj).map((key) => convertFun(obj[key]));
		};

		// Function to transform string keys from object to numbber
		const stringKeysToNumberKeys = <T, M>(obj: { [id: string]: T }, convertFun: (t: T) => M): { [id: number]: M } => {
			if (!obj) return {};
			const newObject: { [id: number]: M } = {};
			for (const key in obj) {
				// eslint-disable-next-line no-prototype-builtins
				if (obj.hasOwnProperty(key) && !isNaN(parseInt(key))) {
					newObject[parseInt(key)] = convertFun(obj[key]);
				}
			}
			return newObject;
		};

		const families: F[] = BKPatternUtilities.generateProductFamiliesWithContent(
			values(bigdata.productFamilies, (t) => <IBKProductFamilyData>t),
			values(bigdata.products, (t) => <IBKProductBase>t)
		);
		return {
			$products: values<IBKProductBase, P>(bigdata.products, (t) => <P>t),
			$productsById: stringKeysToNumberKeys<IBKProductBase, P>(bigdata.products, (t) => <P>t),
			$familiesById: _BK.getItemListByIds<F>(families),
			$menus: values<IBKMenuBase, M>(bigdata.menus, (t) => <M>t),
			$menusById: stringKeysToNumberKeys<IBKMenuBase, M>(bigdata.menus, (t) => <M>t),
			$ingredients: values<IBKIngredientData, T>(bigdata.ingredients, (t) => <T>t),
			$ingredientsById: stringKeysToNumberKeys<IBKIngredientData, T>(bigdata.ingredients, (t) => <T>t)
		};
	}

	/**
	 * 6h shift for a business day.
	 * Per BKFR request (DEV-2160) the time shift is removed. To be sure not to
	 * interfere with any functionality, the timeshift se changed to 0 instead of
	 * completely removing it. Eventually it may be made configurable if needed.
	 */
	public static readonly BUSINESSDAY_TIMESHIFT_MS: number = 0;

	// public static readonly BUSINESSDAY_TIMESHIFT_MS: number = 6 * 60 * 60 * 1000;

	/**
	 * Fix the timestamp so that it stays in the same day but at timeshift
	 */
	public static fixTimestampToBusinessDayStart(timestamp: number): number {
		return Shar3dUtils.getClosestLocalMidnightTimestamp(timestamp) + _BK.BUSINESSDAY_TIMESHIFT_MS;
	}

	/**
	 * Fix the timestamp so that it stays in the same day but at timeshift
	 */
	public static fixTimestampToBusinessDayEnd(timestamp: number): number {
		return Shar3dUtils.getClosestLocalMidnightTimestamp(timestamp) + _BK.BUSINESSDAY_TIMESHIFT_MS - 1;
	}

	public static readonly now: () => number =
		Date.now ||
		function (): number {
			return new Date().getTime();
		};

	public static readonly nowISO: () => string = () => new Date().toISOString();

	/**
	 * Check for a planification
	 */
	public static checkForPlanification(p: IBKTimePlanification, refTime: number): boolean {
		//  Now process the date
		const d: Date = new Date(refTime);
		if (_BK.isDefined(p.dayOfWeekMask) && p.dayOfWeekMask !== 0) {
			//  Get the week day, converting 0=Sunday to 6=Sunday
			const weekDay: number = (d.getDay() + 6) % 7;
			const mask: number = Math.pow(2, weekDay);
			if ((p.dayOfWeekMask! & mask) === 0) {
				return false;
			}
		}
		//  Process the hour
		if (p.hourlyLimited) {
			const startMinutes: number = p.startHour * 60 + p.startMinute;
			const endMinutes: number = p.endHour * 60 + p.endMinute;
			const testMinutes: number = d.getHours() * 60 + d.getMinutes();
			if (startMinutes !== endMinutes) {
				if (testMinutes < startMinutes || testMinutes >= endMinutes) {
					return false;
				}
			}
		}
		//  Finished
		return true;
	}

	public static checkForCampaignFiltered(
		campaignId: string,
		source: BKOrderSourceEnum,
		campaignFilters: { [campaignId: string]: number[] }
	): boolean {
		if (_BK.isUndefined(campaignFilters)) {
			return false;
		}
		if (source === BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN) {
			return false;
		}
		if (_BK.isUndefined(campaignFilters[campaignId])) {
			return false;
		}
		//  Check for the source to be contained
		const a: number[] = campaignFilters[campaignId];
		return _BK.contains(a, source);
	}

	/**
	 * Check for the array to contain an element
	 */
	public static contains<T>(a: T[], b: T): boolean {
		return a.indexOf(b) >= 0;
	}

	/**
	 * Returns the last index on an array that passes a predicate test
	 * Returns -1 if no item in array passed the predicate test
	 */
	public static findLastIndex<T>(list: T[], predicate: (t: T) => boolean): number {
		if (!list) return -1;
		for (let i = list.length - 1; i >= 0; i--) {
			if (predicate(list[i])) {
				return i;
			}
		}
		return -1;
	}

	/**
	 * Get a parameter value from the query-string variables
	 */
	public static getKeyValueFromQuery(query: string, key: string): string | undefined {
		if (!key) {
			return undefined;
		}
		const orderEventsArgs: { [key: string]: string } = _BK.getQueryVariables(query || '');
		return orderEventsArgs[key];
	}

	public static stringToBoolean(value: string | undefined): boolean | undefined {
		if (value === undefined) {
			return undefined;
		}
		return value.toLowerCase() === 'true';
	}
}

export class BKAvailableUtils {
	/**
	 * Check for the ingredient of a product to be available (used by bk-js-lib)
	 */
	public static checkForProductIngredientAvailable(
		p: IBKProductBase,
		ingredientsById: { [id: string]: { available: boolean; active: boolean; campaignItem?: boolean; inActiveCampaign?: boolean } },
		config?: {
			skipCampaignCheck?: boolean;
		}
	): boolean {
		//  Check for a recipe
		if (!p._ingredients) {
			return true;
		}
		//  Check for the length
		if (p._ingredients.length <= 0) {
			return true;
		}
		//  Now lookup
		for (let i = 0; i < p._ingredients.length; i++) {
			const igref = p._ingredients[i];
			if (igref.ingredientDefault <= 0 || igref.initialQty <= 0) {
				continue;
			}
			const ig = _BK.isDefined(ingredientsById[igref.ingredientDefault]) ? ingredientsById[igref.ingredientDefault] : null;
			if (ig == null) {
				continue;
			}
			if (!ig.available || !ig.active) {
				return false;
			}
			// Check campaign: warning to undefined properties so keep like that, camparing only to boolean value
			const skipCampaignCheck: boolean = config && config.skipCampaignCheck ? true : false;
			if (!skipCampaignCheck && ig.campaignItem === true && ig.inActiveCampaign === false) {
				return false;
			}
		}
		//  Finished ok
		return true;
	}
}

export interface IBKTableAllocationAssignment {
	code: string;
	allocationType: BKTableAllocationType;
	locationSpace: BKTableAllocationLocationSpace;
}

export interface IBKCustomerNameAssignment {
	customerName: string;
}

export class BKOrderUtilities {

	public static getLocalisation(order: IBKPublishedOrderData): ICsiExport_elocalisation {
		if (BKOrderEventUtilities.isOrderCreatedFromTabletInRoom(order.event || [])) {
			return ICsiExport_elocalisation.TABLE_SERVICE;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_DRIVE) {
			return ICsiExport_elocalisation.DRIVE_THROUGH;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_WALK) {
			return ICsiExport_elocalisation.WALK;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY) {
			return ICsiExport_elocalisation.DELIVERY;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY) {
			return ICsiExport_elocalisation.DELIVERY;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT) {
			const pickUpType: BKPickUpTypeEnum = BKOrderEventUtilities.getPickUpTypeForWebOrder(order.event || []);
			switch (pickUpType) {
				case BKPickUpTypeEnum.TAKE_AWAY:
					return ICsiExport_elocalisation.CC_TAKE_OUT;
				case BKPickUpTypeEnum.PARKING:
					return ICsiExport_elocalisation.CC_PARKING;
				case BKPickUpTypeEnum.DRIVE:
					return ICsiExport_elocalisation.CC_DRIVE_THROUGH;
				case BKPickUpTypeEnum.ON_SITE:
					return ICsiExport_elocalisation.CC_EAT_IN;
				case BKPickUpTypeEnum.TABLE_SERVICE:
					return ICsiExport_elocalisation.CC_TABLE_SERVICE;
				default:
					return ICsiExport_elocalisation.CC_TAKE_OUT;
			}
		}
		if (BKOrderEventUtilities.isKioskOrderCreatedToBePaidInPos(order.event)) {
			return order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL ? ICsiExport_elocalisation.KIOSK_EAT_IN : ICsiExport_elocalisation.KIOSK_TAKE_OUT;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_KIOSK) {
			return order.deliveryMode === BKDeliveryModeEnum.DELIVERY_LOCAL ? ICsiExport_elocalisation.EAT_IN : ICsiExport_elocalisation.TAKE_OUT;
		}
		switch (order.deliveryMode) {
			case BKDeliveryModeEnum.DELIVERY_LOCAL:
				return ICsiExport_elocalisation.EAT_IN;
			case BKDeliveryModeEnum.DELIVERY_TAKE_OVER:
				return ICsiExport_elocalisation.TAKE_OUT;
		}
		return ICsiExport_elocalisation.EAT_IN;
	}

	public static getWhopperScratcherCouponFromOrder(order: IBKPublishedOrderData): string {
		const event = order.event.find((event) => event.eventType === BKOrderEventType.WHOPPER_SCRATCHER_COUPON);
		if (!event) {
			return undefined;
		}
		return _BK.getKeyValueFromQuery(event.arg, BKOrderEventUtilities.COUPON);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	public static async addWhopperScratcherEventToOrder(order: IBKPublishedOrderData, bigData /*: IBKBigData || BKBigDatas*/, couponGetterCallback: Function): Promise<boolean> {
		const CAMPAIGN_ID = 722;
		let campaign: IBKCampaignData = undefined;
		if (bigData.campaignsById) {
			//BKBigDatas
			campaign = bigData.campaignsById[CAMPAIGN_ID];
		} else if (!Array.isArray(bigData.menus)) {
			//IBKBigData
			campaign = bigData.campaigns[CAMPAIGN_ID];
		} else {
			throw new TypeError('addWhopperScratcherEventToOrder Weird bigData provided');
		}
		if (!campaign) {
			return false;
		}
		const now = new Date().getTime();
		const isCampaignActive = campaign.startDateTime < now && now < campaign.endDateTime;
		if (!isCampaignActive) {
			return false;
		}

		const menuCounter = BKOrderUtilities.countWhopperScratcherMenusInOrder(order, bigData);
		if (!menuCounter) {
			return false;
		}
		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY) {
			return false;
		}
		const existingEvent = order.event.find((event) => event.eventType === BKOrderEventType.WHOPPER_SCRATCHER_COUPON);
		if (existingEvent) {
			const coupon = _BK.getKeyValueFromQuery(existingEvent.arg, BKOrderEventUtilities.COUPON);
			existingEvent.arg = Shar3dUtils.object2URLEncoded({
				[BKOrderEventUtilities.COUPON]: coupon,
				[BKOrderEventUtilities.MENU_COUNT]: menuCounter
			});
			return true;
		}
		const coupon = await couponGetterCallback();
		if (!coupon) {
			console.error('Error in addWhopperScratcherEventToOrder. Coupon is empty');
			return false;
		}
		const event = {
			timestamp: 0,
			eventType: BKOrderEventType.WHOPPER_SCRATCHER_COUPON,
			arg: Shar3dUtils.object2URLEncoded({
				[BKOrderEventUtilities.COUPON]: coupon,
				[BKOrderEventUtilities.MENU_COUNT]: menuCounter
			})
		};
		order.event.push(event);
		return true;
	}

	private static countWhopperScratcherMenusInOrder(order: IBKPublishedOrderData, bigData /*: IBKBigData || BKBigDatas*/) {
		const KINGDOM_MENU_TAG_ID = 72;
		let counter = 0;
		order.orderContent
			.filter((menu: any /*: BKMenuInOrder*/) => {
				if (!menu.selection) {
					//not a menu
					return false;
				}
				if (menu.price.ttc <= 0) {
					return false;
				}
				let bigDataMenu = undefined;
				if (bigData.menusById) {
					//BKBigDatas
					bigDataMenu = bigData.menusById[menu.id];
				} else if (!Array.isArray(bigData.menus)) {
					//IBKBigData
					bigDataMenu = bigData.menus[menu.id];
				} else {
					throw new TypeError('countWhopperScratcherMenusInOrder Weird bigData provided');
				}
				if (!bigDataMenu) {
					return false;
				}
				if (bigDataMenu._kidsMenu) {
					return false;
				}
				if (bigDataMenu.xtag.includes(KINGDOM_MENU_TAG_ID)) {
					return false;
				}
				return true;
			})
			.forEach((menu /*: BKMenuInOrder*/) => {
				counter += menu.qty;
			});
		return counter;
	}
}

export class BKOrderEventUtilities {
	public static KIOSK_PAY_IN_POS_KEY = 'payInPos';
	/**
	 * Seems to be mutually exclusive with EASEL_NUMBER_KEY
	 */
	public static TABLE_NUMBER_KEY = 'tableNum';
	/**
	 * Seems to be mutually exclusive with TABLE_NUMBER_KEY
	 */
	public static EASEL_NUMBER_KEY = 'easelNum';
	public static LOCATION_SPACE_KEY = 'locationSpace';
	public static CUSTOMER_NAME_KEY = 'customerName';
	public static REAL_TIMESTAMP = 'realTimestamp';
	public static CSI_USER_SESSION = 'csiUserSession';
	public static COUPON = 'coupon';
	public static MENU_COUNT = 'menuCount';

	/**
	 * Data for digital payment (event 2016)
	 */
	public static DP_APPLICATION_NAME = 'dpAppName';
	public static DP_CARD_TOKEN = 'dpCardToken';
	public static DP_PAYMENT_SCHEME = 'dpPayScheme';

	public static USER_NAME = 'userName';

	/**
	 * Detect if an Order was created in Kiosk to be paid in POS
	 */
	public static isKioskOrderCreatedToBePaidInPos(orderEvents: IBKOrderEventData[]): boolean {
		if (!orderEvents) return false;
		// Search in order events for the last event INFO_ORDER_KIOSK and if there is info with the 'payInPos' argument set to '1';
		const lastKioskInfoEventIndex: number = _BK.findLastIndex(orderEvents, (oe) => oe.eventType === BKOrderEventType.INFO_ORDER_KIOSK);
		// Get event
		const foundEvent: IBKOrderEventData | undefined = orderEvents[lastKioskInfoEventIndex];
		// Check it
		if (!foundEvent) {
			return false;
		}
		// Convert arg
		return !!parseInt(_BK.getKeyValueFromQuery(foundEvent.arg, BKOrderEventUtilities.KIOSK_PAY_IN_POS_KEY) as string); // Convert "0", "1" and undefined into boolean
	}

	/**
	 * Get the table allocation assignment from the "events" metadata of an Order.
	 * Table can be either "table number" or "easel number"
	 */
	public static getTableAllocationAssignment(orderEvents: IBKOrderEventData[]): IBKTableAllocationAssignment | null {
		if (!orderEvents) {
			return null;
		}

		const tableAllocationEvent: IBKOrderEventData | undefined = orderEvents.find((oe) => oe.eventType === BKOrderEventType.TABLE_ALLOCATION);

		if (tableAllocationEvent) {
			const tableNumber: string | undefined = _BK.getKeyValueFromQuery(tableAllocationEvent.arg, BKOrderEventUtilities.TABLE_NUMBER_KEY);
			const easelNumber: string | undefined = _BK.getKeyValueFromQuery(tableAllocationEvent.arg, BKOrderEventUtilities.EASEL_NUMBER_KEY);
			const locationSpaceObj: { locationSpace: BKTableAllocationLocationSpace } = {
				locationSpace: BKOrderEventUtilities.getSpaceLocationFromTableAllocEvent(tableAllocationEvent)
			};
			if (_BK.isSet(tableNumber) || (!_BK.isSet(tableNumber) && !_BK.isSet(easelNumber))) {
				return {
					code: tableNumber || null,
					allocationType: BKTableAllocationType.TABLE_NUMBER,
					...locationSpaceObj
				};
			}
			if (_BK.isSet(easelNumber)) {
				return {
					code: easelNumber,
					allocationType: BKTableAllocationType.EASEL_NUMBER,
					...locationSpaceObj
				};
			}
		}
		return null;
	}

	/** Get customer name from events (first name selection screen) */
	public static getCustomerNameAssignment(orderEvents: IBKOrderEventData[]): IBKCustomerNameAssignment | null {
		if (!orderEvents) {
			return null;
		}

		const event = orderEvents.find(oe => oe.eventType === BKOrderEventType.TABLE_ALLOCATION);
		if (event) {
			const customerName = _BK.getKeyValueFromQuery(event.arg, BKOrderEventUtilities.CUSTOMER_NAME_KEY);
			if (_BK.isSet(customerName)) {
				return {
					customerName
				};
			}
		}
		return null;
	}

	public static getCallOfDutyCodesFromEvents(orderEvents: IBKOrderEventData[]): string[] {
		const event = orderEvents.find(event => event.eventType === BKOrderEventType.CALL_OF_DUTY_CODES);
		if (!event) {
			return [];
		}
		return JSON.parse(event.arg);
	}

	/**
	 * Get the space location from an table allocation order event
	 */
	private static getSpaceLocationFromTableAllocEvent(tableAllocEvt: IBKOrderEventData): BKTableAllocationLocationSpace {
		const location: string = _BK.getKeyValueFromQuery(tableAllocEvt?.arg || '', BKOrderEventUtilities.LOCATION_SPACE_KEY) || '';
		return location ? (location as BKTableAllocationLocationSpace) : BKTableAllocationLocationSpace.NONE;
	}

	/**
	 * Detect if an order was create by a tablet in room
	 */
	public static isOrderCreatedFromTabletInRoom(orderEvents: IBKOrderEventData[]): boolean {
		if (!orderEvents) return false;
		// Search in order events if there is some about info order tablet with the 'in room' argument set to '1';
		return orderEvents.some((oe: IBKOrderEventData) => {
			if (oe.eventType !== BKOrderEventType.INFO_ORDER_TABLET) return false; // Skip all the other events
			return !!parseInt(_BK.getKeyValueFromQuery(oe.arg, BKInfoOrderTabletEventKey.IN_ROOM) as string); // Convert "0", "1" and undefined into boolean
		});
	}

	/**
	 * Get pick up type for an order for weborder
	 */
	public static getPickUpTypeForWebOrder(orderEvents: IBKOrderEventData[]): BKPickUpTypeEnum {
		for (const oEvent of orderEvents || []) {
			// Search for the event about pick up type
			if (oEvent.eventType !== BKOrderEventType.WEBORDER_META_PICKUP_TYPE) {
				continue;
			}
			// Right event found, return the pick up type set in the argument
			return oEvent.arg as BKPickUpTypeEnum;
		}
		// Default response, no pick up type was found in the list of order events
		return BKPickUpTypeEnum.NONE;
	}

	/**
	 * Get details for Parking pick up type
	 */
	public static getPickUpTypeParkingDetails(orderEvents: IBKOrderEventData[]): IBKPickUpTypeParkingDetails | null {
		for (const oEvent of orderEvents || []) {
			if (oEvent.eventType !== BKOrderEventType.WEBORDER_PICKUP_TYPE_PARKING_DETAILS) {
				continue;
			}
			return <IBKPickUpTypeParkingDetails>JSON.parse(oEvent.arg);
		}
		return null;
	}

	public static isPaxOrder(orderEvents: IBKOrderEventData[]): boolean {
		for (const oEvent of orderEvents || []) {
			if (oEvent.eventType !== BKOrderEventType.INFO_ORDER_SOURCE) {
				continue;
			}
			return true;
		}
		return null;
	}

	public static getDeliveryOperatorName(orderEvents: IBKOrderEventData[]): string {
		for (const oEvent of orderEvents || []) {
			if (oEvent.eventType !== BKOrderEventType.WEBORDER_SANDBOX_NAME) {
				continue;
			}
			return oEvent.arg;
		}
		return null;
	}
	/**
	 * Get old orderUUID from events (added in apps\bk-cash\src\api\bkgetorderfromdriveco.php)
	 */
	public static getOldOrderUUID(orderEvents: IBKOrderEventData[]): string | undefined {
		let oldOrderUUID: string | undefined = undefined;
		for (const oEvent of orderEvents || []) {
			if (oEvent.eventType !== BKOrderEventType.OLD_ORDER_UUID) {
				continue;
			}
			oldOrderUUID = oEvent.arg;
		}
		return oldOrderUUID;
	}

	/**
	 * If the Order has been replaced by DriveCo, then this method returns it old ORB number.
	 * Otherwise undefined.
	 */
	public static getOldORBNumber(orderEvents: IBKOrderEventData[]): number | undefined {
		const matchingEvent = orderEvents.find(x => x.eventType === BKOrderEventType.OLD_ORB_NUMBER);
		if (!matchingEvent) {
			return undefined;
		}

		if (matchingEvent.arg) {
			return parseInt(matchingEvent.arg);
		} else {
			return undefined;
		}
	}

	public static getPatchedPaidDateTimestamp(orderEvents: IBKOrderEventData[]): number | undefined {
		const matchingEvent = orderEvents
			.reverse()
			.find(x => x.eventType === BKOrderEventType.PAID_DATE_PATCHED);

		if (!matchingEvent) {
			return undefined;
		}

		const timestamp: number = parseInt(matchingEvent.arg);

		return isNaN(timestamp)
			? undefined
			: timestamp;
	}
}


/**
 * This export class defines some utilities for handling Kingdom
 */
export class BKKingdomUtils {
	/**
	 * Order is kingdom related if it containts at least one of kingdom events.
	 */
	public static isKingdomRelatedOrder(order: IBKPublishedOrderData): boolean {
		if (!order) {
			return false;
		}
		return (order.event || []).some((oe) => {
			switch (oe.eventType) {
				case BKOrderEventType.KINGDOM_CLIENT_LOGIN:
				case BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER:
					return true;
				default:
					return false;
			}
		});
	}

	public static shouldOrderBeSendToFidelityProvider(order: IBKPublishedOrderData): boolean {
		if (BKKingdomUtils.isKingdomRelatedOrder(order)) {
			return true;
		}
		return (order.event || []).some(event => event.eventType === BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER);
	}

	/**
	 * Get all Kingdom client ids present in order events
	 */
	public static extractKingdomClientIdsFromOrderEvents(orderEvents: IBKOrderEventData[]): string[] {
		const clientIds: string[] = [];
		if (!orderEvents) {
			return clientIds;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			const clientIdFromEvent: string | undefined = BKKingdomUtils.getClientIdFromOrderEvent(orderEvents[i].eventType, orderEvents[i].arg);
			if (clientIdFromEvent && !_BK.contains(clientIds, clientIdFromEvent)) {
				clientIds.push(clientIdFromEvent);
			}
		}
		return clientIds;
	}

	/**
	 * Get all discount uuid consumed by Kingdom privileges in order events
	 */
	public static extractKingdomDiscountUUIDsFromOrderEvents(orderEvents: IBKOrderEventData[]): string[] {
		const uuids: string[] = [];
		if (!orderEvents) {
			return uuids;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			const uuidFromEvent: string | undefined = BKKingdomUtils.getDiscountUUIDFromOrderEvent(orderEvents[i].eventType, orderEvents[i].arg);
			if (uuidFromEvent) {
				uuids.push(uuidFromEvent);
			}
		}
		return uuids;
	}

	/**
	 * Get all Kingdom privileges used in order events
	 */
	public static extractKingdomPrivilegeFromOrderEvents(orderEvents: IBKOrderEventData[]): { privilegeId: string; discountUUID: string }[] {
		const privileges: { privilegeId: string; discountUUID: string }[] = [];
		if (!orderEvents) {
			return privileges;
		}
		for (const oe of orderEvents) {
			if (oe.eventType !== BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER) {
				continue;
			}
			const privilegeId: string | undefined = BKKingdomUtils.getPrivilegeIdFromOrderEvent(oe.eventType, oe.arg);
			const discountUUID: string | undefined = BKKingdomUtils.getDiscountUUIDFromOrderEvent(oe.eventType, oe.arg);
			if (privilegeId && discountUUID) {
				privileges.push({ privilegeId, discountUUID });
			}
		}
		return privileges;
	}

	/**
	 * Function form API discount map
	 */
	public static apiDiscountMapFun(discount: IBKDiscountInOrderDataCassandra, order: IBKPublishedOrderDataCassandra): IBKExchangedOrderDiscount | null {
		if (!discount || !order) {
			return null;
		}
		// Search on order events
		for (let i = 0, max = (order.event || []).length; i < max; i++) {
			const event: IBKOrderEventDataCassandra = order.event[i];
			// Keep only the kingdom and local mailings discount typed event
			switch (event.eventtype) {
				case BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER:
				case BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER:
					break;
				default:
					continue;
			}
			// Try to get the some data from the kingdom order event
			const privilegeId: string | undefined = BKKingdomUtils.getPrivilegeIdFromOrderEvent(event.eventtype, event.arg);
			const discountUUID: string | undefined = BKKingdomUtils.getDiscountUUIDFromOrderEvent(event.eventtype, event.arg);
			if (privilegeId && discountUUID === discount.uuid) {
				// Return the converted discount
				return {
					id: privilegeId
				};
			}
		}
		// Nothing found
		return null;
	}

	/**
	 * Get privilege id from Kingdom order event
	 */
	public static getPrivilegeIdFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		if (orderEventType !== BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER && orderEventType !== BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER) {
			return undefined;
		}
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.PRIVILEGE_ID);
	}

	/**
	 * Get discount uuid from Kingdom order event
	 */
	public static getDiscountUUIDFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		if (orderEventType !== BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER && orderEventType !== BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER) {
			return undefined;
		}
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.DISCOUNT_UUID);
	}

	/**
	 * Get Kingdom client id from order event
	 */
	public static getClientIdFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		// Check order event type
		switch (orderEventType) {
			case BKOrderEventType.KINGDOM_CLIENT_LOGIN:
			case BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER:
				// Right event type so continue
				break;
			default:
				// Wrong event type so finished
				return undefined;
		}
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.CLIENT_ID);
	}

	/**
	 * Get privilege id in an order event for a client id
	 */
	public static getPrivilegeIdInOrderEventForAClient(orderEventType: number, orderEventArg: string, clientId: string): string | undefined {
		// Check parameters
		if (!clientId || orderEventType !== BKOrderEventType.KINGDOM_DISCOUNT_IN_ORDER) {
			return undefined;
		}
		// Check that is the right client id
		if (BKKingdomUtils.getClientIdFromOrderEvent(orderEventType, orderEventArg) === clientId) {
			return BKKingdomUtils.getPrivilegeIdFromOrderEvent(orderEventType, orderEventArg);
		}
		// This was not the right client so nothing to return
		return undefined;
	}

	/**
	 * Get login methed from order event
	 */
	public static getLoginMethodFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		switch (orderEventType) {
			case BKOrderEventType.KINGDOM_CLIENT_LOGIN:
			case BKOrderEventType.BURGER_MYSTERE_LOGIN:
			case BKOrderEventType.DEMOCRATIC_BURGER_LOGIN:
			case BKOrderEventType.LOCAL_MAILINGS_LOGIN:
				return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.LOGIN_METHOD);
			default:
				return undefined;
		}
	}

	/*****************************************************/
	/****             Burger Mystere              ********/

	/*****************************************************/

	/**
	 * Get all burger mystere codes present in order events
	 */
	public static extractBurgerMystereCodesFromOrderEvents(orderEvents: IBKOrderEventData[], couponId?: number): string[] {
		const codes: string[] = [];
		if (!orderEvents) {
			return codes;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			const codeFromEvent: string | undefined = BKKingdomUtils.getBurgerMystereCodeFromOrderEvent(orderEvents[i].eventType, orderEvents[i].arg, couponId);
			if (codeFromEvent && !_BK.contains(codes, codeFromEvent)) {
				codes.push(codeFromEvent);
			}
		}
		return codes;
	}

	/**
	 * Get burger mystere code from Burger Mystere order event
	 */
	public static getBurgerMystereCodeFromOrderEvent(orderEventType: number, orderEventArg: string, couponId?: number): string | undefined {
		if (orderEventType !== BKOrderEventType.BURGER_MYSTERE_LOGIN) {
			return undefined;
		}
		// Check if we check or not the coupon id
		const checkCouponId: boolean = _BK.isDefined(couponId);
		// Get info from the order event
		const couponIdFromEvent: number = parseInt(_BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.COUPON_ID) as string);
		const clientIdFromEvent: string | undefined = _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.CLIENT_ID);
		if (!checkCouponId) {
			return clientIdFromEvent;
		}
		// Check coupon id
		return couponIdFromEvent === couponId ? clientIdFromEvent : undefined;
	}

	/**
	 * Get burger mystere related game uuid from Burger Mystere order event
	 */
	public static getBurgerMystereGameUUIDFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		if (orderEventType !== BKOrderEventType.BURGER_MYSTERE_LOGIN) {
			return undefined;
		}
		return _BK.getKeyValueFromQuery(orderEventArg, BKBurgerMystereInOrderEventKey.GAME_UUID);
	}

	/*****************************************************/
	/****           Democratic Burger             ********/

	/*****************************************************/

	/**
	 * Get all democratic burger codes present in order events
	 */
	public static extractDemocraticBurgerCodesFromOrderEvents(orderEvents: IBKOrderEventData[], couponId?: number): string[] {
		const codes: string[] = [];
		if (!orderEvents) {
			return codes;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			const codeFromEvent: string | undefined = BKKingdomUtils.getDemocraticBurgerCodeFromOrderEvent(orderEvents[i].eventType, orderEvents[i].arg, couponId);
			if (codeFromEvent && !_BK.contains(codes, codeFromEvent)) {
				codes.push(codeFromEvent);
			}
		}
		return codes;
	}

	/**
	 * Get democratic burger code from Democratic Burger order event
	 */
	public static getDemocraticBurgerCodeFromOrderEvent(orderEventType: number, orderEventArg: string, couponId?: number): string | undefined {
		if (orderEventType !== BKOrderEventType.DEMOCRATIC_BURGER_LOGIN) {
			return undefined;
		}
		// Check if we check or not the coupon id
		const checkCouponId: boolean = _BK.isDefined(couponId);
		// Get info from the order event
		const couponIdFromEvent: number = parseInt(_BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.COUPON_ID) as string);
		const clientIdFromEvent: string | undefined = _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.CLIENT_ID);
		if (!checkCouponId) {
			return clientIdFromEvent;
		}
		// Check coupon id
		return couponIdFromEvent === couponId ? clientIdFromEvent : undefined;
	}

	/**
	 * Get democratic burger related game uuid from Democratic Burger order event
	 */
	public static getDemocraticBurgerGameUUIDFromOrderEvent(orderEventType: number, orderEventArg: string): string | undefined {
		if (orderEventType !== BKOrderEventType.DEMOCRATIC_BURGER_LOGIN) {
			return undefined;
		}
		return _BK.getKeyValueFromQuery(orderEventArg, BKBurgerMystereInOrderEventKey.GAME_UUID);
	}

	/*****************************************************/
	/****             Local Mailings              ********/

	/*****************************************************/

	/**
	 * Get all local mailngs codes present in order events
	 */
	public static extractLocalMailingsCodesFromOrderEvents(orderEvents: IBKOrderEventData[], couponId?: number): string[] {
		const codes: string[] = [];
		if (!orderEvents) {
			return codes;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			const codeFromEvent: string | undefined = BKKingdomUtils.getLocalMailingsCodeFromOrderEvent(orderEvents[i].eventType, orderEvents[i].arg, couponId);
			if (codeFromEvent && !_BK.contains(codes, codeFromEvent)) {
				codes.push(codeFromEvent);
			}
		}
		return codes;
	}

	/**
	 * Get local mailings code from Local Mailings order event
	 */
	public static getLocalMailingsCodeFromOrderEvent(orderEventType: number, orderEventArg: string, couponId?: number): string | undefined {
		if (orderEventType !== BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER) {
			return undefined;
		}
		// Check if we check or not the coupon id
		const checkCouponId: boolean = _BK.isDefined(couponId);
		// Get info from the order event
		const couponIdFromEvent: number = parseInt(_BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.COUPON_ID) as string);
		const clientIdFromEvent: string | undefined = _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.CLIENT_ID);
		if (!checkCouponId) {
			return clientIdFromEvent;
		}
		// Check coupon id
		return couponIdFromEvent === couponId ? clientIdFromEvent : undefined;
	}

	/**
	 * Get all discount uuid consumed by Local Mailings coupons in order events
	 */
	public static extractLocalMailingsDiscountUUIDsFromOrderEvents(orderEvents: IBKOrderEventData[]): string[] {
		const uuids: string[] = [];
		if (!orderEvents) {
			return uuids;
		}
		for (let i = 0, max = orderEvents.length; i < max; i++) {
			if (orderEvents[i].eventType !== BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER) {
				continue;
			}
			const uuidFromEvent: string | undefined = BKKingdomUtils.utilGetDiscountUUIDFromOrderEvent(orderEvents[i].arg);
			if (uuidFromEvent) {
				uuids.push(uuidFromEvent);
			}
		}
		return uuids;
	}

	/**
	 * Get all Local Mailings privileges used in order events
	 */
	public static extractLocalMailingsPrivilegeFromOrderEvents(
		orderEvents: IBKOrderEventData[]
	): { discountUUID: string; privilegeId: string; discountId: number; couponId: number; clientId: string }[] {
		const privileges: { discountUUID: string; privilegeId: string; discountId: number; couponId: number; clientId: string }[] = [];
		if (!orderEvents) {
			return privileges;
		}
		for (const oe of orderEvents) {
			if (oe.eventType !== BKOrderEventType.LOCAL_MAILINGS_DISCOUNT_IN_ORDER) {
				continue;
			}
			const privilegeId: string | undefined = BKKingdomUtils.utilGetPrivilegeIdFromOrderEvent(oe.arg);
			const discountId: number = parseInt(BKKingdomUtils.utilGetDiscountIdFromOrderEvent(oe.arg) as string);
			const discountUUID: string | undefined = BKKingdomUtils.utilGetDiscountUUIDFromOrderEvent(oe.arg);
			const couponId: number = parseInt(BKKingdomUtils.utilGetCouponIdFromOrderEvent(oe.arg) as string);
			const clientId: string | undefined = BKKingdomUtils.utilGetClientIdFromOrderEvent(oe.arg);
			if (discountUUID && privilegeId && discountId && couponId && clientId) {
				privileges.push({ discountUUID, privilegeId, discountId, couponId, clientId });
			}
		}
		return privileges;
	}

	/********************************/
	/************  UTILS ************/

	/********************************/
	/**
	 * Get coupon id from order event
	 */
	public static utilGetCouponIdFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.COUPON_ID);
	}

	/**
	 * Get privilege id from order event
	 */
	public static utilGetPrivilegeIdFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.PRIVILEGE_ID);
	}

	/**
	 * Get discount id from order event
	 */
	public static utilGetDiscountIdFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.DISCOUNT_ID);
	}

	/**
	 * Get discount uuid from order event
	 */
	public static utilGetDiscountUUIDFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.DISCOUNT_UUID);
	}

	/**
	 * Get client id from order event
	 */
	public static utilGetClientIdFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.CLIENT_ID);
	}

	/**
	 * Get login method from order event
	 */
	public static utilGetLoginMethodFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.LOGIN_METHOD);
	}

	public static utilGetKingdomClientIdFromOrderEvent(orderEventArg: string): string | undefined {
		return _BK.getKeyValueFromQuery(orderEventArg, BKKingdomInOrderEventKey.KINGDOM_CLIENT_ID);
	}

	/**
	 * Check if the order is kingdom related and vip. Vip is set in order events.
	 */
	public static isVipOrder(order: IBKPublishedOrderData): boolean {
		if (!BKKingdomUtils.isKingdomRelatedOrder(order)) {
			return false;
		}

		return order.event.some(orderEvents => {
			const isVip = _BK.getKeyValueFromQuery(orderEvents.arg, BKKingdomInOrderEventKey.KINGDOM_CLIENT_VIP);
			return isVip === 'true';
		});
	}
}

/**
 * This export class represents a campaign
 */
export class BKCampaignDataWithLogic extends BKCampaignDataImpl {
	public availableCommon: boolean;
	public availableByFidelity: boolean;

	public available: boolean;

	constructor(c: IBKCampaignData) {
		super(c);
		//  Patch the start and begin to match the business date
		this.startDateTime = _BK.fixTimestampToBusinessDayStart(this.startDateTime);
		this.endDateTime = _BK.fixTimestampToBusinessDayEnd(this.endDateTime);
		//  Do some patch
		if (_BK.isUndefined(c.dayOfWeekMask)) this.dayOfWeekMask = 0;
		if (_BK.isUndefined(c.hourlyLimited)) this.hourlyLimited = false;
		if (_BK.isUndefined(c.kioskNavigationInCampaign)) this.kioskNavigationInCampaign = [];
		if (_BK.isUndefined(c.cashNavigationInCampaign)) this.cashNavigationInCampaign = [];
		if (_BK.isUndefined(c.gameInCampaign)) this.gameInCampaign = [];
		if (_BK.isUndefined(c.daypart)) this.daypart = false;
		if (_BK.isUndefined(c.daypartSelection)) this.daypartSelection = [];
		if (_BK.isUndefined(c.daypartProductPrices)) this.daypartProductPrices = [];
		if (_BK.isUndefined(c.daypartMenuPrices)) this.daypartMenuPrices = [];
		if (_BK.isUndefined(c.couponInCampaign)) this.couponInCampaign = [];
		if (_BK.isUndefined(c.sourceMask)) this.sourceMask = BKOrderSourceMask.ORDER_SOURCE_NONE;
		if (_BK.isUndefined(c.planifications)) this.planifications = [];
		//  Init the available ( false because of the source )
		this.updateCampaignAvailable(_BK.now(), {}, {}, BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN, false, 0);
		//  Finished
		return;
	}

	/**
	 * Update the campaign available value based on referential time, fidelity etc.!
	 */
	public updateCampaignAvailable(
		refTime: number,
		campaignAvl: { [id: number]: boolean },
		campaignFilters: { [campaignId: string]: number[] },
		orderSource: BKOrderSourceEnum,
		fidelityLogged: boolean,
		fidelityPoints: number,
		showDebugLogs: boolean = false,
	): boolean {
		let b: boolean = _BK.isDefined(campaignAvl[this.id]) ? campaignAvl[this.id] : this.enable;
		if (showDebugLogs) console.log(`updateCampaignAvailable: initial value = ${b}`);
		//  Check for the source
		if (b && this.sourceMask !== BKOrderSourceMask.ORDER_SOURCE_NONE) {
			//  In case of source unknown, disable the campaign
			if (orderSource === BKOrderSourceEnum.ORDER_SOURCE_UNKNOWN) {
				b = false;
			} else {
				b = (this.sourceMask & _BK.flagToMask(orderSource)) > 0;
			}
			if (showDebugLogs) console.log(`updateCampaignAvailable: post source filtering (${orderSource}) = ${b}`);
		}
		//  Check for filtered
		if (b) {
			b = !this.checkCampaignFiltered(campaignFilters, orderSource);
			if (showDebugLogs) console.log(`updateCampaignAvailable: post campaign filtered = ${b}`);
		}
		//  If it is active, check for the time
		if (b) {
			b = this.checkTimeLimit(refTime);
			if (showDebugLogs) console.log(`updateCampaignAvailable: post time limit = ${b}`);
		}
		//  Check for a more complexe planification
		if (b && _BK.isDefined(this.planifications) && this.planifications !== null) {
			if (this.planifications.length > 0) {
				b = false;
				this.planifications.forEach((p) => {
					b = b || _BK.checkForPlanification(p, refTime);
				});
				if (showDebugLogs) console.log(`updateCampaignAvailable: post planification = ${b}`);
			}
		}
		//  Assign the common
		this.availableCommon = b;
		if (showDebugLogs) console.log(`updateCampaignAvailable: availableCommon = ${b}`);

		//  Finished, return the result for easy chaining
		return this.updateCampaignAvailableByFidelity(fidelityLogged, fidelityPoints, showDebugLogs);
	}

	/**
	 * Update the campaign availability based only on the fidelity parameters
	 * @param fidelityLogged
	 * @param fidelityPoints
	 */
	public updateCampaignAvailableByFidelity(fidelityLogged: boolean, fidelityPoints: number, showDebugLogs: boolean = false): boolean {
		if (showDebugLogs) console.log(`updateCampaignAvailableByFidelity: initial availableByFidelity = ${this.availableByFidelity}, fidelity logged: ${fidelityLogged}, fidelity points: ${fidelityPoints}`);
		//  No fidelity means available by default
		if (_BK.isUndefined(this.fidelity)) {
			this.availableByFidelity = true;
		} else {
			switch (this.fidelity.filter) {
				default:
				case BKCampaignFidelityFilterTypeEnum.NONE:
					this.availableByFidelity = true;
					break;
				case BKCampaignFidelityFilterTypeEnum.IDENTIFIED:
					this.availableByFidelity = fidelityLogged;
					break;
				case BKCampaignFidelityFilterTypeEnum.IDENTIFIED_ABOVE_LIMIT:
					this.availableByFidelity = fidelityLogged && fidelityPoints >= (this.fidelity.limit1 || 0);
					break;
				case BKCampaignFidelityFilterTypeEnum.IDENTIFIED_BETWEEN_LIMITS:
					this.availableByFidelity = fidelityLogged && fidelityPoints >= (this.fidelity.limit1 || 0) && fidelityPoints < (this.fidelity.limit2 || 0);
					break;
				case BKCampaignFidelityFilterTypeEnum.NOT_IDENTIFIED:
					this.availableByFidelity = !fidelityLogged;
					break;
			}
		}
		if (showDebugLogs) console.log(`updateCampaignAvailableByFidelity: post switch availableByFidelity = ${this.availableByFidelity}/${this.availableCommon}`);
		//  Mix
		this.available = this.availableCommon && this.availableByFidelity;
		//  Finished
		return this.available;
	}

	private checkCampaignFiltered(campaignFilters: { [campaignId: string]: number[] }, source: BKOrderSourceEnum): boolean {
		return _BK.checkForCampaignFiltered(this.id.toString(), source, campaignFilters);
	}

	/**
	 * Check for the time limit!
	 */
	private checkTimeLimit(refTime: number): boolean {
		if (refTime < this.startDateTime) return false;
		if (refTime > this.endDateTime) return false;
		//  Check for hourly limited or dayly, if not, finished
		if (!this.hourlyLimited && this.dayOfWeekMask === 0) {
			return true;
		}
		//  Now process the date
		const d: Date = new Date(refTime);
		if (this.dayOfWeekMask !== 0) {
			//  Get the week day, converting 0=Sunday to 6=Sunday
			const weekDay: number = (d.getDay() + 6) % 7;
			const mask: number = Math.pow(2, weekDay);
			if ((this.dayOfWeekMask & mask) === 0) {
				return false;
			}
		}
		//  Process the hour
		if (this.hourlyLimited) {
			const startMinutes: number = this.startHour * 60 + this.startMinute;
			const endMinutes: number = this.endHour * 60 + this.endMinute;
			const testMinutes: number = d.getHours() * 60 + d.getMinutes();
			if (startMinutes !== endMinutes) {
				if (testMinutes < startMinutes || testMinutes >= endMinutes) {
					return false;
				}
			}
		}
		//  Finished
		return true;
	}
}

export enum BKCampaignPriceAdjustMethodEnum {
	DELTA,
	PERCENT,
	ABSOLUTE,
}

/**
 * Simple export interface used in order to store price adjust informations
 */
export interface IBKCampaignPriceAdjust {
	//priceAdjustInValue: boolean;
	priceAdjustMethod: BKCampaignPriceAdjustMethodEnum;
	priceAdjust: number;
	priceAdjustL: number;
}

export interface IBKCampaignAllPrices {
	active: boolean;
	restrictive: boolean;
	products: { [id: number]: IBKCampaignPriceAdjust };
	productsExtras: { [id: number]: IBKCampaignPriceAdjust };
	menus: { [id: number]: IBKCampaignPriceAdjust };
	ingredients: { [id: number]: IBKCampaignPriceAdjust };
}

/**
 * This export class holds the content of the campaign, i.e. IDs of all the items in the campaign.
 */
export class BKCampaignContents {
	public campaignProducts: number[] = [];
	public campaignMenus: number[] = [];
	public campaignIngredients: number[] = [];
	public campaignMedias: number[] = [];
	public campaignMediasPlaylist: number[] = [];
	public campaignDiscounts: number[] = [];
	public campaignOptions: number[] = [];
	public campaignSuggestions: number[] = [];
	public campaignGames: number[] = [];
	public campaignCoupons: number[] = [];
	public daypartPrices: IBKCampaignAllPrices = {
		products: {},
		productsExtras: {},
		menus: {},
		ingredients: {},
		restrictive: false,
		active: false
	};

	private static className = `[BKCampaignContents]`;

	public static computeCampaignContents<P extends IBKProductBase,
		M extends IBKMenuBase,
		D extends IBKDiscountData,
		A extends IBKAnnotationData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>,
		C extends IBKCouponServiceData,
		T extends IBKIngredientData>(
			$campaigns: BKCampaignDataWithLogic[],
			checkCampaignAvl: boolean,
			$campaignAvailability: { [campaignId: number]: boolean },
			$campaignFilters: { [campaignId: string]: number[] },
			$campaignPrices: { [campaignId: string]: IBKCampaignLocalPricesData },
			orderSource: BKOrderSourceEnum,
			data: IBKBigDataProcessed<P, M, D, A, G, F, C, T>,
			refDate: number,
			fidelityLogged: boolean,
			fidelityPoints: number
		): BKCampaignContents {
		//  This function has been kept for compatibility reasons
		if (checkCampaignAvl) {
			for (const c of $campaigns) {
				c.updateCampaignAvailable(
					refDate, $campaignAvailability, $campaignFilters, orderSource, fidelityLogged, fidelityPoints);
			}
		}
		return BKCampaignContents.computeCampaignContentsNoUpdate($campaigns, $campaignPrices, data);
	}

	public static computeCampaignContentsNoUpdate<P extends IBKProductBase,
		M extends IBKMenuBase,
		D extends IBKDiscountData,
		A extends IBKAnnotationData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>,
		C extends IBKCouponServiceData,
		T extends IBKIngredientData>(
			$campaigns: BKCampaignDataWithLogic[],
			$campaignPrices: { [campaignId: string]: IBKCampaignLocalPricesData },
			bigData: IBKBigDataProcessed<P, M, D, A, G, F, C, T>
		): BKCampaignContents {
		const cc: BKCampaignContents = new BKCampaignContents();
		for (const c of $campaigns) {
			//  Check for adding elements
			if (c.available) {
				//  Products
				if (c.productsInCampain.length > 0) cc.campaignProducts = _BK.union(cc.campaignProducts, c.productsInCampain);
				//  Menus
				if (c.menusInCampain.length > 0) cc.campaignMenus = _BK.union(cc.campaignMenus, c.menusInCampain);
				//  Ingredients
				if (c.ingredientsInCampain.length > 0) cc.campaignIngredients = _BK.union(cc.campaignIngredients, c.ingredientsInCampain);
				//  Medias
				if (c.mediasInCampain.length > 0) cc.campaignMedias = _BK.union(cc.campaignMedias, c.mediasInCampain);
				//  Medias playlist
				if (c.mediaPlaylistsInCampain.length > 0) cc.campaignMediasPlaylist = _BK.union(cc.campaignMediasPlaylist, c.mediaPlaylistsInCampain);
				//  Discount
				if (c.discountInCampain.length > 0) cc.campaignDiscounts = _BK.union(cc.campaignDiscounts, c.discountInCampain);
				//  Options
				if (c.optionsInCampain.length > 0) cc.campaignOptions = _BK.union(cc.campaignOptions, c.optionsInCampain);
				//  Suggestions
				if (c.suggestionInCampaign.length > 0) cc.campaignSuggestions = _BK.union(cc.campaignSuggestions, c.suggestionInCampaign);
				//  Games
				if (c.gameInCampaign.length > 0) cc.campaignGames = _BK.union(cc.campaignGames, c.gameInCampaign);
				if (c.couponInCampaign.length > 0) cc.campaignCoupons = _BK.union(cc.campaignCoupons, c.couponInCampaign);
				//  Check for a daypart
				if (c.daypart) {
					//  Process informations from the daypart
					cc.processDaypart(c, cc.daypartPrices, bigData);
					//  Check for an overwritten prices
					if (_BK.isDefined($campaignPrices[c.id])) {
						const localPrices: IBKCampaignLocalPricesData = $campaignPrices[c.id];
						//  Product
						if (_BK.isDefined(localPrices.daypartProductPrices)) {
							if (_BK.isUndefined(cc.daypartPrices.products)) {
								cc.daypartPrices.products = {};
							}
							for (const dpp of localPrices.daypartProductPrices) {
								cc.daypartPrices.products[dpp.id] = {
									priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
									priceAdjust: dpp.price || 0,
									priceAdjustL: dpp.priceL || 0
								};
							}
						}
						//  Product Extra
						if (_BK.isDefined(localPrices.daypartProductExtraPrices)) {
							if (_BK.isUndefined(cc.daypartPrices.productsExtras)) {
								cc.daypartPrices.productsExtras = {};
							}
							for (const dpp of localPrices.daypartProductExtraPrices) {
								if (!cc.daypartPrices.productsExtras[dpp.id]) {
									continue;
								}
								cc.daypartPrices.productsExtras[dpp.id] = {
									priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
									priceAdjust: dpp.price || 0,
									priceAdjustL: dpp.priceL || 0
								};
							}
						}
						//  Menu
						if (_BK.isDefined(localPrices.daypartMenuPrices)) {
							if (_BK.isUndefined(cc.daypartPrices.menus)) {
								cc.daypartPrices.menus = {};
							}
							for (const dpp of localPrices.daypartMenuPrices) {
								if (!cc.daypartPrices.menus[dpp.id]) {
									// Normal behavior, no need to log that
									// console.log(`${this.className} Could not find daypart price for menu with id: ${dpp.id}`);
								} else {
									cc.daypartPrices.menus[dpp.id] = {
										priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
										priceAdjust: _BK.isUndefined(dpp.price) ? cc.daypartPrices.menus[dpp.id].priceAdjust : dpp.price!,
										priceAdjustL: _BK.isUndefined(dpp.priceL) ? cc.daypartPrices.menus[dpp.id].priceAdjustL : dpp.priceL!
									};
								}
							}
						}
						//  Ingredients
						if (_BK.isDefined(localPrices.daypartIngredientPrices)) {
							if (_BK.isUndefined(cc.daypartPrices.ingredients)) {
								cc.daypartPrices.ingredients = {};
							}
							for (const dpp of localPrices.daypartIngredientPrices) {
								cc.daypartPrices.ingredients[dpp.id] = {
									priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
									priceAdjust: dpp.price || 0,
									priceAdjustL: dpp.priceL || 0
								};
							}
						}
					}
				}
			}
		}
		return cc;
	}

	private processDaypart<P extends IBKProductBase,
		M extends IBKMenuBase,
		D extends IBKDiscountData,
		A extends IBKAnnotationData,
		G extends IBKGameData,
		F extends IBKProductFamilyDataWithContent<P, F>,
		C extends IBKCouponServiceData,
		T extends IBKIngredientData>(c: BKCampaignDataWithLogic, daypartPrices: IBKCampaignAllPrices, bigDataProcessed: IBKBigDataProcessed<P, M, D, A, G, F, C, T>): void {
		//  Check for some selection
		if (_BK.isDefined(c.daypartSelection) && c.daypartSelection !== null) {
			//  Set as active
			daypartPrices.active = true;
			//  Process the selection
			for (const sel of c.daypartSelection) {
				const adjust: IBKCampaignPriceAdjust = {
					priceAdjustMethod: sel.priceAdjustInValue ? BKCampaignPriceAdjustMethodEnum.DELTA : BKCampaignPriceAdjustMethodEnum.PERCENT,
					priceAdjust: sel.priceAdjust,
					priceAdjustL: sel.priceAdjust
				};
				daypartPrices.restrictive = daypartPrices.restrictive || sel.showOnlySelected;
				//  Check if there are some, patterns to use
				if (sel.patterns.length > 0) {
					const f: {
						products: IBKProductBase[];
						menus: IBKMenuBase[];
						ingredients: IBKIngredientData[];
					} = BKPatternUtilities.filterMixedForCampaign(
						sel.patterns,
						bigDataProcessed.$products,
						bigDataProcessed.$productsById,
						bigDataProcessed.$familiesById,
						bigDataProcessed.$menus,
						bigDataProcessed.$menusById,
						bigDataProcessed.$ingredients,
						bigDataProcessed.$ingredientsById
					);
					for (const pb of f.products) {
						daypartPrices.products[pb.id] = adjust;
						daypartPrices.productsExtras[pb.id] = adjust;
					}
					for (const mb of f.menus) {
						daypartPrices.menus[mb.id] = adjust;
					}
					for (const ib of f.ingredients) {
						daypartPrices.ingredients[ib.id] = adjust;
					}
				} else {
					daypartPrices.products[0] = adjust;
					daypartPrices.menus[0] = adjust;
					daypartPrices.ingredients[0] = adjust;
				}
			}
		}
		//  Check for specific prices for the products
		if (_BK.isDefined(c.daypartProductPrices)) {
			for (const p of c.daypartProductPrices) {
				daypartPrices.products[p.id] = {
					priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
					priceAdjust: p.price || 0,
					priceAdjustL: p.priceL || 0
				};
			}
		}
		//  Check for specific prices for the products extras
		if (_BK.isDefined(c.daypartProductExtraPrices)) {
			for (const p of c.daypartProductExtraPrices) {
				daypartPrices.productsExtras[p.id] = {
					priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
					priceAdjust: p.price || 0,
					priceAdjustL: p.priceL || 0
				};
			}
		}
		//  Check for specific prices for the menus
		if (_BK.isDefined(c.daypartMenuPrices)) {
			for (const m of c.daypartMenuPrices) {
				daypartPrices.menus[m.id] = {
					priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
					priceAdjust: m.price!,
					priceAdjustL: m.priceL || 0
				};
			}
		}
		//  Check for specific prices for the menus
		if (_BK.isDefined(c.daypartIngredientPrices)) {
			for (const g of c.daypartIngredientPrices) {
				daypartPrices.ingredients[g.id] = {
					priceAdjustMethod: BKCampaignPriceAdjustMethodEnum.ABSOLUTE,
					priceAdjust: g.price || 0,
					priceAdjustL: g.priceL || 0
				};
			}
		}
	}
}
